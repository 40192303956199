import { register } from '@netcentric/component-loader';
import config from './batcom-navigationheader.config';
import { isOnDesktop } from '../../../commons/utils';
import * as Events from '../../../commons/constants/events';

class NavigationHeader {
  constructor(el) {
    this.el = el.firstElementChild;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.burgerMenuIsActive = false;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
    this.addContactLink(this.headerLink);
    this.handleNavDisplay();
    this.setObserver();
    this.checkStickyContainer();
  }

  setRefs() {
    this.body = document.body;
    this.header = document.querySelector(this.selectors.header);
    this.headerLink = this.header.querySelector(this.selectors.headerLink);
    this.searchMenu = this.header.querySelector(this.selectors.searchMenu);
    this.languageNavigationMenu = this.header.querySelector(this.selectors.languageNavigationMenu);
    this.navWrapper = this.el.parentElement;
    this.navTopGroup = this.el.querySelector(this.selectors.navTopGroup);
    this.topLevelNavLink = this.el.querySelectorAll(this.selectors.topLevelNavLink);
    this.navGroup = this.el.querySelectorAll(this.selectors.navGroup);
    this.menuButton = this.el.parentElement.querySelector(this.selectors.menuButton);
    this.navBase = this.header.querySelector(this.selectors.navBase);
    this.stickyContainer = document.querySelector(this.selectors.stickyContainer);
  }

  setEventListeners() {
    window.addEventListener('resize', () => this.onWindowResize());
    this.menuButton.addEventListener('click', event => this.onMenuClick(event));
  }

  checkStickyContainer() {
    if (this.stickyContainer) {
      this.navBase.classList.add(this.classes.hasStickyTop);
    }
  }

  onWindowResize() {
    if (isOnDesktop() && !this.burgerMenuIsActive) {
      this.handleNavDisplay();
    }
  }

  onMenuClick(event) {
    event.preventDefault();
    const menuIsOpen = this.menuButton.classList.contains(this.classes.open);

    if (!menuIsOpen) {
      this.closeHeaderComponents();
    }
  }

  setObserver() {
    const observer = new MutationObserver(this.handleNavDisplay.bind(this, true));
    observer.observe(this.body, { childList: true, subtree: true });
  }

  addContactLink(headerLink) {
    const contactLink = NavigationHeader.getContactLink(headerLink);

    if (contactLink && this.navTopGroup) {
      const contactLinkClone = contactLink.cloneNode(true);
      contactLinkClone.classList.add(this.classes.contact);
      this.navTopGroup.insertBefore(contactLinkClone, null);
    }
  }

  handleNavDisplay(hasMutated) {
    if (isOnDesktop()) {
      if (hasMutated) {
        this.header = document.querySelector(this.selectors.header);
        this.el = this.header.querySelector(this.selectors.navBase);
        this.navWrapper = this.header.querySelector(this.selectors.navWrapper);
        this.navWrapper.classList.add(this.classes.navigationHeader);
        this.navTopGroup = this.header.querySelector(this.selectors.navTopGroup);
        this.menuButton = this.header.querySelector(this.selectors.menuButton);
      }

      const navItemsWidth = NavigationHeader.getWidth(this.navTopGroup);
      const navMaxWidth = NavigationHeader.getMaxWidth(this.navWrapper);
      const hasBurgerMenuActiveClass = this.menuButton.classList.contains(this.classes.burgerMenuActive);

      if (navItemsWidth > navMaxWidth || hasBurgerMenuActiveClass) {
        this.header.classList.add(this.classes.burgerMenuActive);
        this.navWrapper.classList.add(this.classes.burgerMenuActive);
        this.el.classList.add(this.classes.burgerMenuActive);
        this.menuButton.classList.add(this.classes.burgerMenuActive);
        this.burgerMenuIsActive = true;
      } else {
        this.header.classList.remove(this.classes.burgerMenuActive);
        this.menuButton.classList.remove(this.classes.burgerMenuActive);
      }
    }

    this.showNav();
  }

  showNav() {
    if (this.navTopGroup) {
      this.navTopGroup.classList.add(this.classes.visible);
    }
  }

  closeHeaderComponents() {
    if (this.searchMenu.classList.contains(this.classes.open)) {
      window.PubSub.publish(Events.CLOSE_SEARCH);
    }

    if (this.languageNavigationMenu?.classList.contains(this.classes.open)) {
      window.PubSub.publish(Events.CLOSE_LANGUAGE_NAVIGATION);
    }
  }

  static getContactLink(headerLink) {
    return headerLink.getElementsByTagName('a')[0];
  }

  static getWidth(element) {
    const elementStyles = element ? window.getComputedStyle(element) : '0px';
    return elementStyles.maxWidth ? Number(elementStyles.width.split('px')[0]) : 0;
  }

  static getMaxWidth(element) {
    const elementStyles = element ? window.getComputedStyle(element) : '0px';
    return elementStyles.maxWidth ? Number(elementStyles.maxWidth.split('px')[0]) : 0;
  }
}

register({ NavigationHeader });
