export default {
  selectors: {
    navWrapper: '.batcom-navigation',
    navBase: '.cmp-navigation',
    topLevelNavLink: '.cmp-navigation__item--level-0 > .cmp-navigation__item-link',
    navTopGroup: '.cmp-navigation > .cmp-navigation__group',
    navGroup: '.cmp-navigation__group',
    menuButton: '.batcom-menuButton__base',
    header: '.batcom-header',
    headerLink: '.batcom-header__link',
    searchMenu: '.cmp-search__menu',
    languageNavigationMenu: '.cmp-languagenavigation',
    stickyContainer: '.batcom-container.batcom-sticky-top',
  },
  classes: {
    open: 'open',
    visible: 'visible',
    burgerMenuActive: 'batcom-burgerMenu--active',
    contact: 'cmp-navigation__item-link--contact',
    navigationHeader: 'batcom-navigation--header',
    hasStickyTop: 'batcom-navigation--has-sticky-top',
  },
};
